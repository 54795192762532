<template>
  <div class="">
    <v-header></v-header>
    <div class="content">
      <van-tabs nav-class="tab" color="#333" title-inactive-color="#333" animated swipeable>
        <van-tab v-for="item in wxGroupTypes" :title="item.name">
          <div class="wx-group-tab-pane">
            <wx-group-list :typeId="item.id"/>
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>


-------------------- script --------------------

<script>
  import {wxGroupTypeV2Api} from "../../api/v2/wxGroupTypeV2Api";
  import wxGroupList from './wxGroupList';

  export default {
    components: {wxGroupList},
    data() {
      return {
        wxGroupTypes: [],
      }
    },


    created() {
      this.loadData();
    },


    methods: {
      async loadData() {
        // 加载分类
        let wxGroupTypes = await wxGroupTypeV2Api.findAll();
        wxGroupTypes = [{
          name: '全部',
          typeId: ''
        }, ...wxGroupTypes];

        this.wxGroupTypes = wxGroupTypes;
      }

    }
  }
</script>


-------------------- style --------------------

<style scoped lang="less">
  .content {
    margin-top: var(--header-height);
    height: calc(100vh - var(--header-height));
    background: #f3f3f5;
  }

  .wx-group-tab-pane{
    background: #f3f3f5;
  }
</style>
