import {v2Request} from './v2Request';


export const wxGroupV2Api = {

  async findByTypeId(query) {
    return v2Request.post(`wxGroup/findByTypeId`, query);
  },


};
