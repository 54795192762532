<template>
  <van-pull-refresh v-model="wxGroups.refreshing" @refresh="loadData(wxGroups,true)">
    <van-list v-model="wxGroups.loading" @load="loadData(wxGroups,false)" :finished="!wxGroups.hasMore"
              :immediate-check="false"
    >
      <div v-for="(item,index) in wxGroups.records" class="wx-group-item">
        <div class="wx-group-left">
          <img class="wx-group-avatar" :src="item.avatarUrl">
          <div class="wx-group-info">
            <div class="wx-group-name">{{item.name}}</div>
            <div class="wx-group-desc">{{item.desc}}</div>
          </div>
        </div>
        <div class="wx-group-right">
          <div class="wx-group-btn" @click="join(item)">加入</div>
        </div>
      </div>
      <div class="bottom-text" v-if="!wxGroups.hasMore">
        <div v-if="wxGroups.records && wxGroups.records.length">灵感到底了~</div>
        <div v-else
             class="no-data-box">
          <img src="https://cdn.001ppt.cn/h5/assets/images/noData.png" width="150"
               style="position: relative;top: -100px;">
          <span class="no-data-text">噢！这可能有点儿空 (⊙v⊙)</span>
        </div>
      </div>
      <div class="bottom-text" v-else-if="wxGroups.loading">加载中</div>
      <div class="bottom-text" v-else>加载更多</div>
    </van-list>
  </van-pull-refresh>
</template>


-------------------- script --------------------

<script>
  import {wxGroupV2Api} from "../../api/v2/wxGroupV2Api";
  import {sleep} from "../../config/util";
  import { ImagePreview } from 'vant';

  export default {
    props: {
      typeId: {
        type: String
      }
    },

    watch: {
      typeId: {
        handler() {
          this.loadData(this.wxGroups, true);
        },
        immediate: true
      }
    },

    data() {
      return {
        wxGroups: {
          current: 0,
          pageSize: 20,
          records: [],
          refreshing: false,
          loading: false,
          hasMore: true,
          api({current, size, typeId}) {
            return wxGroupV2Api.findByTypeId({current, size, typeId})
          },
        },
      }
    },


    methods: {

      join(item){
        ImagePreview([item.qrCodeUrl])
      },

      // 加载数据
      async loadData(model, isRefresh) {
        let current = 1;
        let {pageSize} = model;
        if (isRefresh) {
          model.refreshing = true;
        } else {
          model.loading = true;
          current = model.current + 1;
        }
        model.current = current;

        try {
          const res = await model.api({current, pageSize, size: pageSize, typeId: this.typeId});
          model.current = res.current;
          model.pageSize = res.pageSize || res.size;
          model.hasMore = res.total > model.pageSize * model.current;

          // 处理records
          let records = isRefresh ? [] : model.records;
          for (const row of res.records) {
            records.push({...row})
          }
          model.records = records;
        } catch (e) {
          model.current--;
        } finally {
          model.loading = false;
          await sleep(300);
          model.refreshing = false;
        }
      }

    }
  }
</script>


-------------------- style --------------------

<style scoped lang="less">
  .wx-group-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    margin: 12px;
    background: #fff;
    border-radius: 6px;
  }

  .wx-group-left{
    display: flex;
    align-items: center;
  }

  .wx-group-avatar{
    height: 75px;
    width: 75px;
    margin-right: 16px;
    border-radius: 12px;
    flex-shrink: 0;
    border: 1px solid #eee;
  }

  .wx-group-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 150px;
  }

  .wx-group-name{
    font-size: 14px;
    font-weight: bolder;
    margin-bottom: 8px;
    white-space: pre-wrap;
  }

  .wx-group-desc{
    font-size: 12px;
    color: #999;
    white-space: pre-wrap;
  }

  .wx-group-right{
    display: flex;
    align-items: center;
  }

  .wx-group-btn{
    padding: 6px 16px;
    background: #151d36;
    color: #fff;
    border-radius: 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }


  .no-data-box {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative
  }

  .no-data-box .no-data-text {
    position: relative;
    top: -100px;
    margin-top: 10px;
    color: #999;
    font-size: 12px;
    opacity: .25;
  }

  .bottom-text{
    color: #999;
    text-align: center;
    padding: 16px 0;
  }


  .no-data-box{
    margin: 200px 0;
  }


</style>
