import { v2Request } from './v2Request';


export const wxGroupTypeV2Api = {

  async findAll() {
    return v2Request.get(`/wxGroupType/findAll`);
  },


};
